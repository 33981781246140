<template>
  <div class="bg-white padding" style="min-height: 70vh;">
    <el-button type="primary" disabled @click="dialogFormVisible = true">短信助手</el-button>

    <div class="margin-top">
      <el-dialog title="短信助手" :visible.sync="dialogFormVisible" width="42%">
        <el-form :model="form">
          <el-form-item label="收信人:" :label-width="formLabelWidth">
            <div class="flex aligin-items-center">
              <el-input v-model="form.name" autocomplete="off" style="width: 350px;" />
              <div class="margin-left"><el-button type="primary" @click="chooseConect">选择联系人</el-button></div>
            </div>
          </el-form-item>
          <el-form-item label="">
              <div class="flex aligin-items-center justify-content-around" style="width: 500px">
                <el-checkbox v-model="checked">添加客户称呼</el-checkbox>
                <el-dropdown>
                  <span class="el-dropdown-link">
                    签名<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>黄金糕</el-dropdown-item>
                    <el-dropdown-item>狮子头</el-dropdown-item>
                    <el-dropdown-item>螺蛳粉</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <span @click="toPage">
                  <i class="el-icon-time el-icon--left" />联系记录
                </span>
              </div>
            </el-form-item>
          <!-- <div class="flex aligin-items-center justify-content-around padding" style="width: 500px">
            <el-checkbox v-model="checked">添加客户称呼</el-checkbox>
            <el-dropdown>
              <span class="el-dropdown-link">
                签名<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>黄金糕</el-dropdown-item>
                <el-dropdown-item>狮子头</el-dropdown-item>
                <el-dropdown-item>螺蛳粉</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span>
              <i class="el-icon-time el-icon--left" />联系记录
            </span>
          </div> -->
          <el-form-item label="短信内容" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="form.desc" style="width: 450px;" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">发送营销短信</el-button>
          <el-button type="primary" @click="dialogFormVisible = false">发送消息</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formLabelWidth: '100px',
      checked: true
    };
  },
  created() {},
  mounted() {},
  methods: {
    chooseConect() {
      this.$Dialog.ChooseContact().then(res => { 
      });
    },
    toPage() {
      this.dialogFormVisible = false
      this.$router.push('../contactRecord/index')
    }
  }
};
</script>

<style scoped>
>>> .el-textarea textarea {
  height: 100px !important;
}
</style>
